<template>
    <div class="login-box">
        <front-header chooseTitle="present" background="rgba(255, 255, 255, 0.3)" :positionShow="true" :fontcolor="'#fff'"
                      :logo="'0'"></front-header>
        <div class="login-content">
            <div>
                <div class="login-content-left">
                    <div class="login-content-left-logo"></div>
                    <div class="login-content-left-text">
                        &emsp;&emsp;锐鹿保是一家服务企业及个人的普惠保险平台，依托互联网信息技术，深挖企业运营场景风险和个人风险，从而提供专业性更强、成本更低、更有保障的保险产品和一站式风险解决方案。公司总部位于衡水，由保险公司核心团队
                        + 资深互联网专家组成，通过互联网思维方式和运作方式为保险行业带来产品创新与体验升级，共同构建服务于通信电力、物流、制造业、科技服务、建筑施工以及医保特定领域人群的保险科技新生态。
                    </div>
                </div>
                <div class="login-content-right">
                    <div class="login-content-right-text"><p :class="onWay==1?'activate':''" @click="cutlogin(1)">扫码登录</p><p  :class="onWay==2?'activate':''"  @click="cutlogin(2)">手机验证码登录</p></div>
                    <div class="login-content-right-input" v-if="onWay==2">
                        <el-input class="right-input1"
                                  placeholder="请输入手机号码"
                                  prefix-icon="iconfont iconshoujihao"
                                  v-model="phone" @blur="verifyphone()">
                        </el-input>
                        <div>
                            <el-input class="right-input2"
                                      placeholder="请输验证码"
                                      prefix-icon="iconfont iconyanzhengma"
                                      v-model="sms_code">
                            </el-input>
                            <el-input type="button" class="right-input3" @click.native="getsms()" v-model="codebtn"></el-input>
                            <!--                            <input type="text" pl>-->
                        </div>
                    </div>
                    <el-button class="login-content-right-button" type="primary" @click="login()" round v-if="onWay==2">登录</el-button>
                    <div class="qrbox" v-if="onWay==1">
                        <div class="imgbox">
                            <img :src="vximg" alt="" v-if="vximg!=''">
                            <img src="../../image/login/sm.jpg" alt="" v-else>
                            <div class="mark" v-if="vximglose">
                                <p>二维码已失效</p>
                                <p @click="getVxCode()">刷新二维码</p>
                            </div>
                        </div>
                        <p>微信扫码登陆，安全又便捷</p>
                    </div>
                    <div class="channel">
                        <a href="http://www.ruilubao.com:8802" target="_blank" >锐鹿核心销售入口<i class="iconfont iconjiantou"></i><i class="iconfont iconjiantou"></i></a>
                        <!-- <div class="ps"><i></i> 渠道入口搬到这里来了</div> -->
                    </div>
                    


                </div>
            </div>
            <div class="login-content-bottom">
            </div>
        </div>
        <el-dialog
        title="绑定手机号"
        :visible.sync="bindingVisible"
        width="400px"
        top="30vh"
        close-on-click-modal:true
        center>
            <div class="bindingconter">
                <p>随时随地手机看保单</p>
                <el-input class="right-input1"
                            placeholder="请输入手机号码"
                            prefix-icon="iconfont iconshoujihao"
                            v-model="phone" @blur="verifyphone()">
                </el-input>
                <div class="yz">
                    <el-input class="right-input2"
                                placeholder="请输验证码"
                                prefix-icon="iconfont iconyanzhengma"
                                v-model="sms_code">
                    </el-input>
                    <el-input type="button" class="right-input3" @click.native="getsms()" v-model="codebtn"></el-input>                        
                </div>

            </div>            
            <span slot="footer" class="dialog-footer">
                <el-button class="login-content-right-button" type="primary" @click="login(1)" round >登录</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import frontHeader from "../../components/front/frontHeader";
    export default {
        name: "login",
        components: {
            frontHeader,
        },
          data() {
            return {
                phone:"",
                sms_code:'',
                codebtn:"获取验证码",
                time:60,
                vximg:"",//二维码
                vximglosetime:300,//二维码失效时间
                vximglose:false,//二维码失效状态
                onWay:1,//1扫码 2手机号
                bindingVisible:false,//绑定手机号弹窗
                vx_code:"",//扫码返回
            }
        },
        created() {
            this.pro_num = this.$route.query.pro_num
            this.work_id = this.$route.query.work_id
        },
        mounted:function(){
            console.log(this.pro_num)
            this.getVxCode()
        },
        methods: {
            // 手机号验证
            verifyphone:function(){
                console.log(this.phone)
                if (!(/^1\d{10}$/.test(this.phone))) {
                    this.$message.error('手机号不正确,请重新输入');
                    this.phone=""
                }
            },
            //切换登陆方式
            cutlogin:function(e){
                this.onWay=e
            },
            // 获取二维码
            getVxCode:function(){
                let that=this;
                that.axios({
                    url: this.$store.state.baseUrl + '/api/user/user/getVxCode',
                    method: 'POST',
                    params:{
                        source:4,
                        login_type:0,
                    },
                }).then(function(res) {
                        if(res.data.code==200){
                            that.vximglose=false
                            that.vximg=res.data.data.imgUrl
                            let interval=setInterval(function() {
                                    that.vximglosetime=that.vximglosetime - 1
                                    that.vxCodeData(res.data.data.vx_code,interval)
                                    if (that.vximglosetime <= 0) {
                                        clearInterval(interval)
                                        that.vximglosetime=300,
                                        that.vximglose=true
                                    }
                                }, 1000)

                        }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            // 请求扫码返回数据
            vxCodeData:function(code,interval){
                let that=this;
                that.axios({
                    url: this.$store.state.baseUrl + '/api/user/user/checkVxCode',
                    method: 'POST',
                    params:{
                        source:4,
                        login_type:0,
                        vx_code:code,
                    },
                }).then(function(res) {
                    if(res.data.code==2006){
                        that.vx_code=code
                        that.bindingVisible=true
                        clearInterval(interval)
                    }else if(res.data.code==200){
                        clearInterval(interval)
                        localStorage.setItem("logininformation", JSON.stringify(res.data.data));
                        that.$store.state.logininformation=res.data.data
                        that.$store.state.login_type="1"
                        that.$router.push({name:'guaranteeSlip'})
                        if(that.pro_num){//传递产品编号，转跳投保页
                            that.$router.push({
                                name: 'insure',
                                query: {
                                    pro_num: that.pro_num,
                                    work_id: that.work_id
                                }
                            })
                        }else{//传递产品编号没有值转跳我的保单页
                            that.$router.push({name:'guaranteeSlip'})
                        }
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            // 验证码
            getsms: function(){
                let that=this;
                if(that.codebtn=='获取验证码'||that.codebtn=='重新获取'){
                    if(that.phone){
                        that.axios({
                            url: this.$store.state.baseUrl + '/api/user/user/getSms',
                            method: 'POST',
                            params:{
                                authCheck:0,
                                source:4,
                                login_type:0,
                                phone:that.phone,
                            },
                        }).then(function(res) {
                            console.log(res)
                             if(res.data.code==200){
                                that.$message.success("验证码已发送，请注意查收");
                                that.codebtn="已发送(60秒)" 
                                let interval=setInterval(function() {
                                    that.time=that.time - 1,
                                    that.codebtn="已发送("+that.time+"秒)"                            
                                    if (that.time <= 0) {
                                        clearInterval(interval)
                                        that.time=60,
                                        that.codebtn="重新获取"
                                    }
                                }, 1000)
                            }
                        }).catch(function (error) {
                            console.log(error);
                        });
                    }else{
                        that.$message.error('手机号不可以为空');
                    }
                }else{
                    that.$message.warning('验证码已发送请勿重复提交');
                }
                
            },
            // 登陆
            login: function (type) {
                let that=this;
                if(that.phone){
                    if(that.sms_code){
                        let loading=that.$loading({
                            lock: true,
                            text: 'Loading',
                            background: 'rgba(0, 0, 0, 0.5)'
                        });
                        let _data={
                            authCheck:0,
                            source:4,
                            login_type:0,
                            phone:that.phone,
                            sms_code:that.sms_code
                        }
                        if(type==1){
                            _data.vx_code=that.vx_code
                        }
                        that.axios({
                            url: this.$store.state.baseUrl + '/api/user/user/login',
                            method: 'POST',
                            params:_data,
                        }).then(function(res) {
                            console.log(res)
                            loading.close();
                            if(res.data.code==200){
                                localStorage.setItem("logininformation", JSON.stringify(res.data.data));
                                that.$store.state.logininformation=res.data.data
                                that.$store.state.login_type="1"
                                that.$router.push({name:'guaranteeSlip'})
                                if(that.pro_num){//传递产品编号，转跳投保页
                                    that.$router.push({
                                        name: 'insure',
                                        query: {
                                            pro_num: that.pro_num,
                                            work_id: that.work_id
                                        }
                                    })
                                }else{//传递产品编号没有值转跳我的保单页
                                    that.$router.push({name:'guaranteeSlip'})
                                }
                            }else{
                                that.$message.error(res.data.msg);
                            }
                        }).catch(function (error) {
                            console.log(error);
                        });
                    }else{
                        that.$message.error('请输入验证码');
                    }
                }else{
                    that.$message.error('请输入手机号');
                }
            },            
        }
    }
</script>

<style scoped lang="scss">
    .login-box {
        // position: fixed;
        // top: 0;
        // left: 0;
        // right: 0;
        // bottom: 0;
        background: url("../../image/login/back.png");
        background-size: cover;
        width: 100%;
        height: 100vh;

            .login-content {
                width: 1067px;
                height: 721px;
                position: absolute;
                left: calc(50% - 533.5px);
                top: calc(50% - 260.5px);
                display: flex;
                flex-wrap: wrap;
            }
            .login-content > div:nth-child(1) {
                width: 100%;
                display: flex;
            }
            .login-content-left {
                width: 600px;
                height: 600px;
                background: url("../../image/login/back1.png");
                background-size: cover;
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-content: flex-start;

                .login-content-left-logo {
                    width: 108px;
                    height: 146px;
                    margin-top: 82px;
                    background: url("../../image/login/logo2.png");
                    background-size: cover;
                }

                .login-content-left-text {
                    margin-top: 53px;
                    width: 516px;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    line-height: 36px;
                    text-align: left;
                }
            }
            .login-content-right {
                width: 468px;
                height: 600px;
                background: white;

                .login-content-right-text {
                    width: 300px;
                    box-sizing: border-box;
                    font-size: 24px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(13, 111, 183, 1);
                    line-height: 36px;
                    margin-top: 150px;
                    margin-left: 87px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    
                    &>p{
                        margin: 0;
                        font-size:18px;
                        font-family:PingFang SC;
                        font-weight:400;
                        color:rgba(51,51,51,1);
                        cursor: pointer;                        
                        padding-bottom: 5px;
                        border-bottom: 3px solid transparent;

                    }
                    &>p+p{
                        margin-left: 30px;
                    }
                    &>p.activate{
                        color:rgba(13,111,183,1);
                        border-bottom-color:rgba(13,111,183,1);
                    }

                }

                .login-content-right-input {
                    width: 298px;
                    margin-left: 88px;
                    margin-top: 41px;

                    .right-input1 {
                        width: 298px;
                        height: 48px;
                    }

                    .right-input2 {
                        width: 175px;
                        height: 48px;
                    }

                    .right-input3 {
                        width: 109px;
                        height: 48px;
                        cursor: pointer;
                    }

                    & > div {
                        display: flex;
                        justify-content: space-between;
                    }
                }
                .login-content-right-button{
                    width:300px;
                    height:50px;
                    border-radius: 50px;
                    margin-top: 63px;
                }

                .qrbox{
                    width:300px;
                    margin: 50px auto 0;
                    &>.imgbox{
                        width: 160px;
                        margin: 0 auto;
                        position: relative;
                        border:3px solid #ddd;
                        img{
                            max-width: 100%;
                        }
                        .mark{
                            position: absolute;
                            top:0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            align-items: center;
                            flex-direction: column;
                            background: rgba(255,255,255,0.8);

                            p{
                                margin: 0;
                                font-size:18px;
                                font-family:PingFang SC;
                                font-weight:600;
                                color:rgba(51,51,51,1);
                            }
                            p+p{
                                font-size:16px;
                                font-family:PingFang SC;
                                font-weight:600;
                                color:rgba(255,255,255,1);
                                background:rgba(13,111,184,1);
                                margin-top: 15px;
                                padding: 5px 10px;
                                cursor: pointer;
                                border-radius: 5px;
                            }

                        }
                    }
                    &>p{
                        font-size:14px;
                        font-family:PingFang SC;
                        font-weight:500;
                        color:rgba(51,51,51,1);  
                    }
                }

                .input-style {
                    background: rgba(247, 245, 251, 1);
                    border-radius: 24px;
                }

                .login-content-right-input > input {
                    width: 100%;
                }
                .channel{
                    width: 298px;
                    margin-left: 88px;
                    margin-top: 41px;
                    text-align: right;
                    a{                        
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color:#008df7;
                        text-decoration: none;
                        transition: all 0.25s;

                        i{
                            font-size: 12px;
                        }
                        i+i{
                            margin-left: -2px;
                        }
                    }
                    a:hover{
                        color:#008df7;
                    }
                }

            }
            .login-content-bottom {
                width: 100%;
                height: 121px;
                background: url("../../image/login/back2.png");
            }
       
        .bindingconter{
            &>p{
                font-size:14px;
                font-family:PingFang SC;
                font-weight:600;
                color:rgba(102,102,102,1); 
                padding-bottom: 20px;
                margin:0;
            }
            .right-input1 {
                width: 100%;
                height: 48px;
            }

            .right-input2 {
                width:calc(100% - 120px);
                height: 48px;
            }

            .right-input3 {
                width: 109px;
                height: 48px;
                cursor: pointer;
            }

            & > .yz {
                display: flex;
                justify-content: space-between;
                margin-top:20px;
            }            
        }
        .dialog-footer{
            .login-content-right-button{
                width:300px;
                height:50px;
                border-radius: 50px;
                margin-top: 50px;
                margin-bottom: 50px;
            }
        }

        // @media screen and (max-width: 1100px) {
        //     .login-content {
        //         background: red;
        //     }
        // }
    }

// 临时提醒使用
    .login-box .login-content-right .channel{
        position: relative;

        .ps{
            position: absolute;
            top: 30px;
            right: 0;
            background: #fff;
            min-width: 200px;
            border-radius: 4px;
            border: 1px solid #ebeef5;
            padding: 12px;
            z-index: 2000;
            color: #333;
            line-height: 1.4;
            text-align: justify;
            font-size: 20px;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
            word-break: break-all;

            i{
                display: block;
                width: 10px;
                height: 10px;
                background: #fff;
                position: absolute;
                top: -6px;
                right: 30px;
                transform: rotate(-45deg);
            }
        }
    }
</style>